import { IconMail, IconPhoneCall } from "@tabler/icons-react";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>CAR</span> Rental
              </li>
              <li>
                Nous proposons une large gamme de véhicules pour tous vos besoins de conduite. Nous avons la voiture parfaite pour répondre à vos besoins.
              </li>
              <li>
                <a href="tel:123456789">
                  <IconPhoneCall /> &nbsp; (+212) 6 42 29 39 69
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                carrental@gmail.com"
                >
                  <IconMail />
                  &nbsp; onclick@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/onclick_agency/"
                >
                  Design by OnClick
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Entreprise</li>
              <li>
                <a href="#home">Rabat</a>
              </li>
              <li>
                <a href="#home">Casablanca</a>
              </li>
              <li>
                <a href="#home">Fes</a>
              </li>
              <li>
                <a href="#home">Tanger</a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Heures de travail</li>
              <li>Lundi - Vend : 9 h - 21 h</li>
              <li>Samedi : 9 h - 19 h</li>
              <li>Dimanche : fermé</li>
            </ul>

            <ul className="footer-content__2">
              <li>Abonnement</li>
              <li>
                <p>Abonnez-vous avec votre adresse e-mail pour recevoir les dernières nouvelles et mises à jour.</p>
              </li>
              <li>
                <input type="email" placeholder="Entrer votre adresse E-mail"></input>
              </li>
              <li>
                <button className="submit-email">Envoyer</button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
