import { IconMail, IconMailOpened, IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { IconLocation } from "@tabler/icons-react";

function Contact() {
  return (
    <>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Besoin d'informations supplémentaires ?</h2>
              <p>Un professionnel polyvalent compétent dans plusieurs domaines de recherche, de développement ainsi qu'en tant que spécialiste de l'apprentissage. Plus de 15 ans d'expérience.</p>
              <a href="#">
                <IconPhone /> &nbsp; (+212) 6 42 29 39 69
              </a>
              <a href="#">
                <IconMail /> &nbsp; onclick@gmail.com
              </a>
              <a href="#">
                <IconLocation />
                &nbsp; Belgrade, Serbia
              </a>
            </div>
            <div className="contact-div__form">
              <form>
                <label>
                  Nom & Prénom <b>*</b>
                </label>
                <input type="text" placeholder='Exemple : "BENNANI Ahmed"'></input>

                <label>
                  Email <b>*</b>
                </label>
                <input type="email" placeholder="votre-email@example.com"></input>

                <label>
                  Message <b>*</b>
                </label>
                <textarea placeholder="Ecrivez ici.."></textarea>

                <button type="submit">
                  <IconMailOpened />
                  &nbsp; Envoyer le message
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Réservez une voiture en nous contactant</h2>
              <span>
                <IconPhone width={40} height={40} />
                <h3>(+212) 6 42 29 39 69</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
